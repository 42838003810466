import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LoginComponent } from "./core/components/login/login.component";
import { LogoutComponent } from "./core/components/logout/logout.component";
import { HomeComponent } from "./core/components/home/home.component";
import { DashbaordComponent } from "./core/components/dashbaord/dashbaord.component";

import { AuthGuard } from "./../app/auth/guards/auth.guards";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "logout", component: LogoutComponent },
  {
    path: "home",
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        component: DashbaordComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
